<template>
  <div style="height: 100%"><div :id="id"></div></div>
</template>

<script>
import { openFile } from '@/api/onlyoffice'

// 编辑器配置项，完整配置项参见：https://api.onlyoffice.com/editors/config/
const config = {
  // 定义用于访问文档的平台类型，mobile desktop
  type: 'mobile',
  // 编辑器宽度
  width: '100%',
  // 编辑器高度
  height: '100%',
  // 编辑器类型，支持 word（文档）、cell（表格）、slide（PPT）
  documentType: 'word',
  // 文档配置
  document: {
    // 文件类型
    fileType: 'docx',
    // 文档标识符
    key: `${new Date().getTime().toString('32')}`,
    // 文档标题
    title: 'new.docx',
    // 文档地址，绝对路径
    url: `${process.env.WORD_TEMPLATE_URL}`,
    // 权限
    permissions: {
      // 定义是否在文档中启用聊天功能
      chat: false,
      // 定义是否可以评论文档
      comment: false,
      // 定义是否可以打印文档
      print: false,
      // 定义工具栏上的 保护 选项卡和左侧菜单中的 保护 按钮是显示 (true) 还是隐藏 (false)
      protect: false
    }
  },
  // 编辑器配置
  editorConfig: {
    // 自定义编辑器界面
    customization: {
      // 定义附加操作按钮是显示在编辑器窗口标题的上部靠近徽标 (false) 还是显示在工具栏 (true) 中，使标题更紧凑。
      compactHeader: true,
      // 定义显示的顶部工具栏类型是完整的 (false) 还是紧凑的 (true)。
      compactToolbar: false,
      // 定义顶部工具栏选项卡是清晰显示 (false) 还是仅突出显示以查看选择了哪个 (true)。
      toolbarNoTabs: false,
      // 定义编辑器主题设置
      uiTheme: 'theme-classic-light',
      // 定义 注释 菜单按钮是显示还是隐藏。 请注意，如果您隐藏 评论 按钮，则相应的评论功能将仅供查看，无法添加和编辑评论
      comments: false,
      // 定义 帮助 菜单按钮是显示还是隐藏
      help: false,
      // 定义 插件 是否将启动并可用
      plugins: false
    },
    // 定义编辑器界面语言，zh-CN en-US
    lang: 'en-US',
    // 指定 文档存储服务 的绝对 URL
    callbackUrl: `${process.env.VUE_APP_ONLYOFFICE_CALLBACK}`
  }
}

export default {
  data() {
    return {
      id: `editor-${new Date().getTime().toString('32')}`,
      appName: null,
      appBundleId: null,
      appStoreVersion: '',
      appUserId: null,
      tempDownloadUrl: null
    }
  },
  mounted() {
    // this.initEditor({
    //   key: new Date().getTime().toString('32'),
    //   title: 'new.docx',
    //   url: 'https://weboffice.officehelperx.com/template/new.docx'
    // })
    window.initParam = this.initParam
    window.openTemplateFile = this.openTemplateFile
    window.openTemplateFileV2 = this.openTemplateFileV2
    window.openNewFile = this.openNewFile
    window.openNewFileV2 = this.openNewFileV2
    window.openPhoneFile = this.openPhoneFile
    window.openPhoneFileV2 = this.openPhoneFileV2
    window.openAppFile = this.openAppFile
    window.openAppFileV2 = this.openAppFileV2
    window.saveFile = this.saveFile
    window.getTempDownloadUrl = this.getTempDownloadUrl
    window.handlerDestroy = this.handlerDestroy
  },
  beforeDestroy() {
    // 组件销毁前销毁编辑器
    if (this.editor) {
      this.editor.destroyEditor()
      this.editor = null
    }
  },
  methods: {
    base64ToFile(base64, fileName) {
      // let arr = base64.split(",");
      // let mime = arr[0].match(/:(.\*?);/)[1];
      // let bstr = atob(arr[1]);
      const bstr = atob(base64)
      let n = bstr.length
      const u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], fileName, {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8'
      })
    },
    // 初始化参数
    initParam(appName, appBundleId, appStoreVersion, appUserId) {
      this.appName = appName
      this.appBundleId = appBundleId
      this.appStoreVersion = appStoreVersion
      this.appUserId = appUserId
    },
    // 打开模板文档
    openTemplateFile(appName, appBundleId, appUserId, fileId, templateUrl) {
      const formData = new FormData()
      formData.append('appName', appName)
      formData.append('appBundleId', appBundleId)
      formData.append('appStoreVersion', this.appStoreVersion)
      formData.append('appUserId', appUserId)
      formData.append('fileId', fileId)
      formData.append('templateType', templateUrl)
      openFile(formData)
        .then((res) => {
          // console.log(res)
          if (res.code === 200) {
            this.initEditor(res.data)
          }
        })
        .finally(() => {
          // console.log('finally')
        })
    },
    // 打开模板文档（V2）
    openTemplateFileV2(fileId, templateUrl) {
      this.openTemplateFile(this.appName, this.appBundleId, this.appUserId, fileId, templateUrl)
    },
    // 打开新文档
    openNewFile(appName, appBundleId, appUserId, fileId) {
      this.openTemplateFile(appName, appBundleId, appUserId, fileId, 'word')
    },
    // 打开新文档（V2）
    openNewFileV2(fileId) {
      this.openNewFile(this.appName, this.appBundleId, this.appUserId, fileId)
    },
    // 打开本地文档
    openPhoneFile(appName, appBundleId, appUserId, fileId, fileName, base64) {
      const formData = new FormData()
      formData.append('appName', appName)
      formData.append('appBundleId', appBundleId)
      formData.append('appStoreVersion', this.appStoreVersion)
      formData.append('appUserId', appUserId)
      formData.append('fileId', fileId)
      if (fileName.indexOf('.docx') === -1) {
        formData.append('fileName', fileName + '.docx')
      } else {
        formData.append('fileName', fileName)
      }
      formData.append('file', this.base64ToFile(base64, fileName))
      openFile(formData)
        .then((res) => {
          // console.log(res)
          if (res.code === 200) {
            this.initEditor(res.data)
          }
        })
        .finally(() => {
          // console.log('finally')
        })
    },
    // 打开本地文档（V2）
    openPhoneFileV2(fileId, fileName, base64) {
      this.openPhoneFile(this.appName, this.appBundleId, this.appUserId, fileId, fileName, base64)
    },
    // 打开应用内文档
    openAppFile(appName, appBundleId, appUserId, fileId, fileName) {
      const formData = new FormData()
      formData.append('appName', appName)
      formData.append('appBundleId', appBundleId)
      formData.append('appStoreVersion', this.appStoreVersion)
      formData.append('appUserId', appUserId)
      formData.append('fileId', fileId)
      if (fileName.indexOf('.docx') === -1) {
        formData.append('fileName', fileName + '.docx')
      } else {
        formData.append('fileName', fileName)
      }
      openFile(formData)
        .then((res) => {
          // console.log(res)
          if (res.code === 200) {
            this.initEditor(res.data)
          }
        })
        .finally(() => {
          // console.log('finally')
        })
    },
    // 打开应用内文档（V2）
    openAppFileV2(fileId, fileName) {
      this.openAppFile(this.appName, this.appBundleId, this.appUserId, fileId, fileName)
    },
    // 保存文件
    saveFile(fileName) {
      // 下载编辑后的文件。 只有在存在 onDownloadAs 事件 时才能调用该方法。
      this.editor.downloadAs('docx')
      // 返回文件固定的远程地址
      return config.document.url
    },
    // 获取临时下载地址
    getTempDownloadUrl() {
      // 返回文件不固定的最新地址
      return this.tempDownloadUrl
    },
    // 手动销毁编辑器
    handlerDestroy() {
      // 销毁编辑器
      this.editor.destroyEditor()
      this.editor = null
    },

    // 初始化编辑器
    initEditor(data) {
      // 基本信息
      config.document.key = data.key
      config.document.title = new Date().getTime().toString('32')
      config.document.url = data.url + '?v=' + new Date().getTime()
      // 事件
      var eventMethods = {
        onAppReady: function () {
          console.log('ONLYOFFICE Document Editor is ready')
        },
        onDownloadAs: (event) => {
          this.tempDownloadUrl = event.data.url
        }
      }
      config.events = {}
      config.events.onAppReady = eventMethods.onAppReady
      config.events.onDownloadAs = eventMethods.onDownloadAs
      // 初始化
      this.tempDownloadUrl = null
      const scriptId = `script-${this.id}`
      const added = document.querySelector(`#${scriptId}`)
      if (!added) {
        const script = document.createElement('script')
        script.id = scriptId
        script.src = process.env.VUE_APP_ONLYOFFICE_API_URL
        script.onload = this.createEditor
        document.head.appendChild(script)
      } else {
        this.createEditor()
      }
    },
    // 创建编辑器
    createEditor() {
      if (this.editor) {
        this.editor.destroyEditor()
        this.editor = null
      }
      this.editor = new window.DocsAPI.DocEditor(this.id, config)
    }
  }
}
</script>
